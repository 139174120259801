* {
  background-color: #080e1b;
  color: #dedede;
  margin: 0;
  scroll-behavior: smooth;
}

.xl-text {
  font-weight: 700;
  font-size: 65px;
}

.md-text {
  font-weight: 500;
  font-size: 24px;
}

.container {
  margin: 50px;
  padding: 50px;
}

.box {
  padding: 12px;
}